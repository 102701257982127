import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { Link } from 'gatsby'

const FullSchedule = (props) => (
  <Layout>
    <Helmet>
      <title>Schedule</title>
      <meta name="description" content="Landing Page" />
    </Helmet>

    <BannerLanding
      header="Class Descriptions"
      subheader="Find The Class That's Right For You!"
    />

    <div id="main">
      <section id="one">
        <dl className="inner">
          <dt>Creative Movement </dt>
          <dd>
            In this class we do the brain dance, play dance games with friends
            to build positive relationships, and develop gross motor skills and
            coordination.{' '}
          </dd>
          <dd>
            Higher levels include preperation for ballet and contemporary dance
            techniques
          </dd>
        </dl>

        <dl className="inner">
          <dt id="boys">Dance For Boys</dt>
          <dd>
            We cover several styles within this class as well as improvisation.
            Currently we are focusing on strength building; floor work; and
            transitioning of weight, tossing ourselves around the room and
            rising from the floor in only seconds.
          </dd>{' '}
        </dl>
        <dl className="inner">
          <dt id="modern">Contemporary Modern Dance</dt>
          <dd>
            A Blend of{' '}
            <a href="#bottom">
              <u>Horton</u>
            </a>
            , Graham,{' '}
            <a href="#bottom">
              <u>Limon</u>
            </a>
            , Release and contemporary dance styles as well as improvisation and
            partnering. Choreography is based around educational themes and
            storylines.
          </dd>{' '}
          <dd>
            Higher Levels give the dancers a strong understanding of these
            codified techniques preparing them for professional programs and
            college programs.
          </dd>
        </dl>

        <dl className="inner">
          <dt id="ballet">Ballet </dt>
          <dd>
            This is a Vaganava ballet class where students study traditional
            ballet technique that is developmentally appropriate for their age:
            learning proper alignment, building strength and sequencing of steps
            in a joyful environment.
          </dd>
          <dd>
            Higher levels infuse Pointe technique in the classes and dancers
            learn professional variations of the pros.
          </dd>
        </dl>

        <dl className="inner">
          <dt id="pointe">Pointe Strengthening </dt>
          <dd>
            Dancers learn the fundamentals, proper placement and develop
            strength to be successful pointe dancers. They go through
            traditional pointe exercises at the barre and across the floor.
          </dd>
        </dl>

        <dl className="inner">
          <dt id="theatre">Musical Theatre</dt>
          <dd>
            A chance to learn acting, voice, set design and dance with various
            teachers in each discipline working towards a musical theatre
            performance.
          </dd>
        </dl>
        <dl className="inner">
          <dt id="hiphop">Performance Based Hip Hop</dt>
          <dd>
            This will be the style of hip hop we see on award shows,  music
            videos, concerts etc. This class is to prepare the dancer who has
            hopes and dreams to make it in the dance industry. We learn how to
            pick up choreography at a fast efficient pace, adapt to different
            styles of dance, and get ready for auditions.
          </dd>
        </dl>

        <dl className="inner">
          <dt id="company">Performance Class </dt>
          <dd>
            For advanced students by invitation or audition only. This is a{' '}
            <a href="landing.js">Coleman Dance Company</a> class for dancers who
            have earned their placement in the company with exemplary
            attendance, character, strength, grace, artistry, and musicality.
          </dd>{' '}
        </dl>

        <dl className="inner">
          <dt id="adult">Ballet For Adults</dt>
          <dd>
            This is an open-level, drop-in class that is engaging and
            challenging; championing artistry, expressions, and a healthy
            lifestyle. Offered for both the beginner, the working professional,
            and all dancers in-between.
          </dd>
        </dl>

        <dl className="inner">
          <dt id="fitness">Fitness For Adults </dt>
          <dd>
            This is a pilates and yoga-based exercise, strengthen, and stretch
            class. People have reported that with this exercise class they have
            higher energy levels, greater flexibility, and feel better about
            themselves and their bodies.
          </dd>
        </dl>
        <p className="inner">
          <Link to="/schedule">Back to Schedule</Link>
        </p>
      </section>
    </div>
  </Layout>
)

export default FullSchedule
